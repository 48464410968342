* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
}

html {
   margin: 0;
   padding: 0;
   width: 100%;
   height: 100vh;
   overflow: hidden;
   
}

body {
   margin: 0;
   padding: 0;
   /* width: 100vw; */
   /* height: 100vh; */
   box-sizing: border-box;
   font-family: 'Good time', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: 'Good time', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}